<template>
  <div>
    <el-row class='default-table'>
      <el-col :span='24'>
        <div v-loading='loadingStatus'
             :element-loading-text='loadingText'
             element-loading-spinner='el-icon-loading'>
          <ApeTable ref='apeTable'
                    :data='dataList'
                    :columns='[]'
                    :loading='loadingStatus'
                    :pagingData='pagingData'
                    @switchPaging='switchPaging'
                    highlight-current-row
                    border
                    @expand-change='expandChange'
                    :expand-row-keys='[expandRowKey]'
                    row-key='id'
                    :summary-method='getSummaries'
                    show-summary
          >
            <el-table-column type='expand' fixed='fixed'>
              <template slot-scope='props'>
                <BonusItemsTable @editBonus='editBonus' :group-data='props.row' :edit-group='editGroup' />
              </template>
            </el-table-column>
            <!--            <el-table-column-->
            <!--                prop="id"-->
            <!--                label="记录ID"-->
            <!--                align="center"-->
            <!--                min-width="80">-->
            <!--              <template slot-scope="{row}">-->
            <!--                <span>{{ row.id }}</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column
              prop='group_name'
              label='组别'
              align='center'
              min-width='100'>
              <template slot-scope='{row}'>
                <el-link type='primary' @click='editBill(row)' v-if='editGroup' :title='`ID:${row.id}`'>
                  {{ row.group_name }}<i
                  class='el-icon-edit'></i></el-link>
                <span :title='`ID:${row.id}`' v-else>{{ row.group_name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop=''
              label='时间（年月）'
              align='center'
              min-width='100'>
              <template slot-scope='{row}'>
                {{ row.year }}年{{ row.month }}月
              </template>
            </el-table-column>
            <el-table-column
              prop='member_total'
              label='成员数量'
              align='center'
              min-width='100'>
              <template slot-scope='{row}'>
                {{ row.items.length }}
              </template>
            </el-table-column>
            <el-table-column
              prop='trend_bonus'
              label='涨粉团队奖金'
              align='center'
              min-width='110'>
              <template slot-scope='scope'>
                <span class='money'>{{ valFormat(scope.row.trend_bonus * 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop='revenue_bonus'
              label='营收团队奖金'
              align='center'
              min-width='110'>
              <template slot-scope='scope'>
                <span class='money'>{{ valFormat(scope.row.revenue_bonus * 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop='total_bonus'
              label='总奖金'
              align='center'
              min-width='110'>
              <template slot-scope='scope'>
                <span class='money'>{{ valFormat(scope.row.trend_bonus * 1 + scope.row.revenue_bonus * 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop='status'
              label='状态'
              align='center'
              min-width='100'>
              <template slot-scope='{row}'>
                <el-tag
                  :type='calcStatusType(row.status)'
                  effect='plain'
                  disable-transitions> {{ row.status_alias }}
                </el-tag>
              </template>
            </el-table-column>
          </ApeTable>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { numberFormat } from '@/utils/utils'
import ApeTable from '@/components/ApeTable'
import BonusItemsTable from './BonusItemsTable'

export default {
  name: 'BonusList',
  components: {
    ApeTable, BonusItemsTable
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          ym: null, group_ids: null, group_name: ''
        }
      }
    },
    editMode: {
      type: Boolean,
      default() {
        return false
      }
    },
    editGroup: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
    loadingText() {
      return `【${this.searchCondition.ym}】的奖金数据加载中...`
    }
  },
  data() {
    return {
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 10,
        offset: 0
      },
      mergeFields: ['group_name'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      expandRowKey: null
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        // console.log('val',val)
        this.initList()
      }
    }
  },

  methods: {
    valFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.group_ids) {
        condition.group_ids = this.searchCondition.group_ids
      }

      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.depth) {
        condition.depth = this.searchCondition.depth
      }
      if (this.searchCondition.ym) {
        condition.ym = this.searchCondition.ym
      }
      if (this.searchCondition.status != null && this.searchCondition.status !== '') {
        condition.status = this.searchCondition.status
      }
      return condition
    },
    async switchPaging() {
      this.expandRowKey = ''
      await this.initList()
    },
    /**
     * 记录当前展开页面
     * @param row
     */
    expandChange(row) {
      this.expandRowKey = row.id
    },
    async initList(type) {
      // console.log('init list')
      this.loadingStatus = true
      this.dataList = []
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, pagingInfo)
      if (searchCondition.ym) {
        let { list, pages } = await this.$api.getPerformanceBonus(searchCondition)
        let firstRowId = list[0] ? list[0]['id'] : ''
        let row = list.find((value) => value.id === this.expandRowKey)
        if (!row) {
          if (firstRowId) {
            this.expandRowKey = firstRowId
          }
        }
        this.$nextTick(() => {
          this.pagingData.total = pages.total
          this.pagingData.offset = pages.offset

          this.dataList = list

        })
      }
      this.loadingStatus = false
    },
    //编辑-明细
    editBonus(row) {
      this.$emit('editBonus', row)
    },
    // 编辑主单
    editBill(row) {
      this.$emit('editBill', row)
    },
    calcStatusType(status) {
      let type = ''
      switch (status) {
        case 0:
          type = 'primary'
          break
        case 1:
          type = 'warning'
          break
        case 2:
          type = 'warning'
          break
        case 3:
          type = 'success'
          break
        default:
          type = 'info'
          break
      }
      return type
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        // if (index === 2) {
        //   sums[index] = '汇总：'
        //   return
        // }
        if (['trend_bonus', 'revenue_bonus', 'total_bonus'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' '
          } else {
            sums[index] = 'N/A'
          }

          switch (column.property) {
            case 'trend_bonus':
            case 'revenue_bonus':
            case 'total_bonus':
              sums[index] = '¥' + this.$utils.numberFormat(sums[index], 2)
              break
            default:
              // sums[index] = this.valFormat(sums[index])
              break
          }
        }

      })

      return sums
    }
  }
}
</script>

<style scoped>

</style>
