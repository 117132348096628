<template>
  <div>
    <el-row class='table-search'>
      <el-col :span='24'>
        <el-form :model='searchCondition' :inline='true' size='mini'>
          <el-form-item>
            <DeptCascaderScope :type='`bonus_data`' ref='refDeptCascaderScope' :show-first-group='false'
                               @selectedDept='selectDept' />
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model='searchCondition.ym'
              type='month'
              value-format='yyyy-MM'
              placeholder='选择月度' style='width: 140px' @change='changeMonth'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select v-model='searchCondition.status' placeholder='审核状态' style='width: 120px' clearable>
              <el-option label='未提交审核' :value='0'></el-option>
              <el-option label='待总监审核' :value='1'></el-option>
              <el-option label='总监已审核' :value='2'></el-option>
              <el-option label='人资已审核' :value='3'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='doSearch' icon='el-icon-search'>查询</el-button>
            <el-button plain type='warning' @click='initBonusData' :loading='initLoading' :disabled='initLoading'
                       icon='el-icon-refresh' v-if="userPermissions.indexOf('bonus_init') !== -1">
              {{ initLoading ? '初始化中...' : '初始化' }}
            </el-button>
            <el-button icon='el-icon-plus' type='primary' @click='showAddMember=!showAddMember'
                       v-if="userPermissions.indexOf('bonus_add_members')>-1">新增
            </el-button>
            <!--            <el-button>审核-历史记录</el-button>-->
            <!--            <el-button>审核-日志</el-button>-->
            <!--            <el-button type="primary" @click="checkFirst">批量-总监审核(开放中)</el-button>-->
            <!--            <el-button type="primary" @click="checkSecond">批量-人资审核(开放中)</el-button>-->
            <!--            <el-button type="warning" @click="revoke"><i class="iconfont icon-fanshenhe"></i> 批量-撤回重审</el-button>-->
          </el-form-item>
          <el-form-item>
            <export-btn type='primary' plain ref='refExportLink' :lint-title='`导出表格`'
                        :can-export="userPermissions.indexOf('bonus_export') !== -1"
                        @export='exportData' />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <!--    添加参与绩效奖金分配的成员-->
    <CreateMember :visible.sync='showAddMember' @saved='doSearch' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ExportLink from '@/components/export/ExportLink'
import MultipleSelectMyGroup from '@/pages/groups/components/MultipleSelectMyGroup'
import CreateMember from '@/pages/performance/bonus/CreateMember'
import ExportBtn from '@/components/export/ExportBtn'

export default {
  name: 'BonusSearch',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: {
    ExportBtn,
    ExportLink, MultipleSelectMyGroup, CreateMember
  },
  data() {
    return {
      searchCondition: { group_id: null, group_ids: [], ym: null, groups: [], depth: 0, status: null },
      initLoading: false,
      showAddMember: false
    }
  },
  methods: {
    initMonth() {
      let date = new Date()
      //分页页面-默认上月
      date.setMonth(date.getMonth() - 1)
      let year = date.getFullYear() + ''
      let month = date.getMonth() + 1
      this.searchCondition.ym = `${year}-${month}`
    },
    /**
     * 部门选择：val-label
     * @param valArr
     * @param labelArr
     * @returns {Promise<void>}
     */
    async selectDept(valArr, labelArr) {
      let group_id = null
      let group_name = null

      if (valArr && valArr.length > 0) {
        this.searchCondition.depth = valArr.length
        let len = valArr.length
        group_id = valArr[len - 1]
        group_name = labelArr[len - 1]
      }
      this.searchCondition.group_id = group_id

      await this.setGroupInfo(group_id, group_name)
    },
    async setGroupInfo(group_id, group_name) {
      // console.log('-----------------')
      // this.$refs['refDeptCascaderScope'].initVal(group_id)
      if (group_id) {
        this.$notify.success(`查看【${group_name}】奖金分配明细`)
      }

      await this.doSearch()
    },

    changeMonth() {
      this.doSearch()
    },
    doSearch() {
      // console.log('---------- doSearch -------')
      let searchCondition = this.handleSearchCondition()

      this.$emit('doSearch', searchCondition)
    },
    handleSearchCondition() {
      let condition = {}
      this.conditionStr = ''
      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.depth) {
        condition.depth = this.searchCondition.depth
      }

      if (this.searchCondition.ym) {
        condition.ym = this.searchCondition.ym
      }

      if (this.searchCondition.status != null && this.searchCondition.status !== '') {
        condition.status = this.searchCondition.status
      }

      return condition
    },
    async exportData() {
      try {
        let searchCondition = this.handleSearchCondition()
        let name = `团队奖金-${searchCondition.ym}`
        let response = await this.$api.exportBonusBill(searchCondition)

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    async initBonusData() {
      this.initLoading = true
      let searchCondition = this.handleSearchCondition()
      let { data } = await this.$api.initPerformanceBonusData(searchCondition)
      if (data) {
        this.$notify.success(`初始化完毕:项目组：${data.total} 个，已初始化： ${data.existTotal}个，本次初始化： ${data.initTotal}个`)
        this.doSearch()
      }
      this.initLoading = false
    },
    //总监审核
    checkFirst() {
      alert('总监审核')
    },
    //人资审核
    checkSecond() {
      alert('人资审核')
    },
    revoke() {
      alert('撤销审核')
    }
  },
  created() {
    //初始化月份

  },
  async mounted() {
    await this.initMonth()
    await this.doSearch()
  }
}
</script>

<style scoped>

</style>
