<template>
  <div>
    <el-select :value="selectedRows" :placeholder="`组别：${myGroupName}`" filterable style="width: 100%"
               @change="choiceGroup" :multiple="true" :collapse-tags="true" clearable>
      <el-option :label="item.display_name" :value="item.name" v-for="(item,idx) in groupOptions"
                 :key="idx"></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "MultipleSelectMyGroup",
  data() {
    return {
      group_id: null,
      selectedRows: [],
      myGroupName: '',
      groups: []
    }
  },
  props: {
    selectedGroups: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      default: ""//revenue_data revenue_enter trend_data trend_enter
    },
    defaultSelected: {
      type: String,
      default: "all",//first
    }
  },
  computed: {
    // 表格数据
    groupOptions() {
      return this.groups
    },
  },
  watch: {
    selectedGroups(val, oldVal) {
      // console.log(oldVal)
      if (val !== oldVal)
        this.selectedRows = val
    }
  },
  created() {

  },
  mounted() {
    this.groupList()
  },
  methods: {
    async groupList() {
      let { groups } = await this.$api.getMyUserMcnGroup(this.type)
      this.groups = groups
      if (this.defaultSelected === 'all') {
        let group_ids = groups.map((group) => {
          return group.name
        })
        this.selectedRows = group_ids
        this.$emit('defaultGroups', group_ids);
      } else {
        let group_ids = [groups[0].name]
        this.selectedRows = group_ids
        this.$emit('defaultGroups', group_ids);
      }
    },
    choiceGroup(val) {
      this.$emit('currentGroups', val);
    }
  }
}
</script>

<style scoped>
.el-select > .el-input {
  width: 200px !important;
}
</style>