<template>
  <div>
    <!--    筛选条件-->
    <bonus-search @doSearch="doSearch"/>

    <!--    列表-->
    <bonus-list ref="refBonusTables" :search-condition="searchCondition" @editBonus="editBonus" @editBill="editBill"
                :edit-group="calcPermission()"/>

    <!--    指定组操作-->
    <BonusEdit :id="currentId" :visible="dialogVisible" @close="closeDialog"/>
  </div>
</template>

<script>
import BonusSearch from './BonusSearch'
import BonusTable from './BonusTable'
import BonusHandle from './BonusHandle'
import BonusEdit from "@/pages/performance/bonus/BonusEdit";
import BonusList from "@/pages/performance/bonus/BonusList";
import { mapGetters } from "vuex";

export default {
  name: "BonusIndex",
  components: {
    BonusList,
    BonusEdit,
    BonusSearch, BonusTable, BonusHandle
  },
  data() {
    return {
      searchCondition: { group_id: null, group_ids: [], ym: null, groups: [],status:null },
      dialogVisible: false,
      currentId: null,
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  methods: {
    doSearch(val) {
      this.searchCondition = val
    },
    closeDialog() {
      this.dialogVisible = false
      if (this.$refs.refBonusTables)
        this.$refs.refBonusTables.initList()
    },
    editBonus(val) {
      this.currentId = val.bill_id
      this.dialogVisible = true
    },
    editBill(val) {
      this.currentId = val.id
      this.dialogVisible = true
    },
    calcPermission() {
      return this.userPermissions.indexOf('bonus_save') > -1 || this.userPermissions.indexOf('bonus_check_1') > -1 || this.userPermissions.indexOf('bonus_check_2') > -1
    }
  }
}
</script>

<style scoped>

</style>
