<template>
  <div>
    <el-cascader
      v-model='value'
      :options='options'
      :props="{ expandTrigger: 'hover' ,value: 'dpt_id', label: 'name', children: 'children'}"
      @change='handleChange' style='width: 100%' clearable></el-cascader>
  </div>
</template>

<script>
export default {
  name: 'EcpDeptSelect',
  data() {
    return {
      data: [],
      options: [],
      deptProps: { value: 'dpt_id', label: 'name', children: 'children' },
      checkStrictly: false,
      filterText: '',
      selection: null,
      checkList: [],
      value: null
    }
  },
  props: {
    groupId: {
      type: String
    }
  },
  watch: {
    filterText(val) {
      this.$refs.deptTree.filter(val)
    }
  },
  methods: {
    //初始化
    init(val) {
      this.value = val ? val : null
    },
    //根据部门名称筛选【一般用于外部调用：指定部门】
    changeDeptByName(name) {
      let dept = this.data.find(value => value.name === name)
      if (dept) {
        this.value = dept.dpt_id
        this.handleChange([dept.parent_id, dept.dpt_id])
      }
    },
    handleChange(selectArr) {
      // console.log(selectArr)
      let deptArr = []
      //获取完整名称
      selectArr.forEach((deptId) => {
        // console.log(deptId)
        let dept = this.data.find(value => value.dpt_id === deptId)
        deptArr.push(dept)
      })
      this.$emit('handleSelect', deptArr)
      return selectArr
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    async getList() {
      let { list } = await this.$api.getDepartmentAll('')
      this.data = list
      this.calcOptions()
    },
    calcOptions() {
      this.options = this.handleTree(this.data, 'dpt_id', 'parent_id', 'children', '1')
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    if (this.data && this.data.length > 0 && this.groupId) {
      this.value = this.groupId
      let dept = this.data.find(value => value.dpt_id === this.value)
      if (dept)
        this.$emit('handleSelect', [dept])
    }
  }
}
</script>

<style scoped>

</style>
